import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'

export const CONSENT_COOKIE_NAME = 'consentGrantedNew'

export enum CookiesAcceptModes {
  ALL_ACCEPTED = 'allAccepted',
  ESSENTIALS_ACCEPTED = 'essentialsAccepted',
  REJECTED_ALL = 'rejectedAll',
}

function gtag() {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer?.push(arguments)
}

export const useAcceptCookies = () => {
  const [acceptedCookies, setAcceptedCookies] =
    useState<CookiesAcceptModes | null>(CookiesAcceptModes.REJECTED_ALL)
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    const cookieValue = Cookies.get(CONSENT_COOKIE_NAME)

    setAcceptedCookies(cookieValue as CookiesAcceptModes)
  }, [])

  useEffect(() => {
    if (!acceptedCookies) {
      setAcceptedCookies(null)
      setIsInitialized(true)
    } else if (acceptedCookies === CookiesAcceptModes.ALL_ACCEPTED) {
      setIsInitialized(true)
      // @ts-expect-error
      gtag('consent', 'update', {
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        ad_storage: 'granted',
        analytics_storage: 'granted',
      })
    } else if (acceptedCookies === CookiesAcceptModes.ESSENTIALS_ACCEPTED) {
      setIsInitialized(true)
      // @ts-expect-error
      gtag('consent', 'update', {
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        ad_storage: 'denied',
        analytics_storage: 'granted',
      })
    }
  }, [acceptedCookies])

  const acceptCookies = (acceptMode: CookiesAcceptModes) => {
    if (acceptMode === CookiesAcceptModes.ALL_ACCEPTED) {
      setIsInitialized(true)
      setAcceptedCookies(CookiesAcceptModes.ALL_ACCEPTED)
      Cookies.set(CONSENT_COOKIE_NAME, CookiesAcceptModes.ALL_ACCEPTED, {
        expires: 365,
      })
    } else if (acceptMode === CookiesAcceptModes.ESSENTIALS_ACCEPTED) {
      setIsInitialized(true)
      setAcceptedCookies(CookiesAcceptModes.ESSENTIALS_ACCEPTED)
      Cookies.set(CONSENT_COOKIE_NAME, CookiesAcceptModes.ESSENTIALS_ACCEPTED, {
        expires: 365,
      })
    } else {
      setAcceptedCookies(CookiesAcceptModes.REJECTED_ALL)
      Cookies.set(CONSENT_COOKIE_NAME, CookiesAcceptModes.REJECTED_ALL, {
        expires: 365,
      })
    }
  }

  return {
    acceptedCookies,
    onAcceptCookies: acceptCookies,
  }
}
