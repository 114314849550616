import { FC, Fragment } from 'react'
import NextHead from 'next/head'
import { GoogleTagManager } from '@next/third-parties/google'
import { useBrandThemeContext } from '@contexts/brandThemeContext'
import { GOOGLE_ANALYTICS_TAG_TYPE } from '@constants/common'
import { useStoreDataContext } from '@contexts/storeDataContext'

const Head: FC = () => {
  const {
    data: { storeTags },
  } = useStoreDataContext()
  const { favicon_url: faviconUrl } = useBrandThemeContext()

  return (
    <>
      <NextHead>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link id='favicon' rel='shortcut icon' href={faviconUrl || ''} />
        <link href={globalThis.location?.origin} rel='canonical' />
        <link rel='manifest' href='/site.webmanifest' key='site-manifest' />
        <link
          rel='preload'
          href='/fonts/Sharp_Sans_No1/Sharp_Sans_No1-Medium.woff2'
          as='font'
          crossOrigin=''
        />
        <link rel='preconnect' href='https://www.googletagmanager.com' />
        <link rel='preconnect' href='https://maps.googleapis.com' />
        <link rel='preconnect' href='https://salesiq.zoho.com' />

        <link
          rel='preload'
          href='/fonts/Sharp_Sans_No1/Sharp_Sans_No1-Semibold.woff2'
          as='font'
          crossOrigin=''
        />
        <link
          rel='preload'
          href='/fonts/Sharp_Sans_No1/Sharp_Sans_No1-Bold.woff2'
          as='font'
          crossOrigin=''
        />
        {Array.isArray(storeTags) &&
          process.env.NEXT_PUBLIC_CONSENT_MODE_ENABLED &&
          storeTags.map(el => (
            <script key={el.id}>
              {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('consent', 'default', {
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'ad_storage': 'denied',
            'analytics_storage': 'denied',
            'wait_for_update': 500,
          });
          gtag('js', new Date());
          gtag('config', '${el.attributes.code.trim()}');
          `}
            </script>
          ))}

        {Array.isArray(storeTags) &&
          storeTags.map(({ id, attributes: { code, type } }) => {
            switch (type) {
              case GOOGLE_ANALYTICS_TAG_TYPE.GOOGLE_ADS:
                return (
                  <Fragment key={id}>
                    <GoogleTagManager gtmId={code} />

                    <script>
                      {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${code}', {'allow_enhanced_conversions': true});
                  `}
                    </script>
                  </Fragment>
                )
              case GOOGLE_ANALYTICS_TAG_TYPE.UA:
                return (
                  <Fragment key={id}>
                    <GoogleTagManager gtmId={code} />
                    <script
                      dangerouslySetInnerHTML={{
                        __html: `
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date()); gtag('config', '${code}');
                      `,
                      }}
                    />
                  </Fragment>
                )
              case GOOGLE_ANALYTICS_TAG_TYPE.GTM:
                return (
                  <Fragment key={id}>
                    <script>
                      {`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${code}');
                    `}
                    </script>
                    <script>
                      {`(function () {
                    const body = document.body;
                    const ns = document.createElement('noscript');
                    const iframe = document.createElement('iframe');
                    iframe.src='https://www.googletagmanager.com/ns.html?id=${code}'
                    iframe.height = 0;
                    iframe.width = 0;
                    iframe.style='display:none;visibility:hidden'
                    ns.appendChild(iframe)
                    body.prepend(ns);
                  })()
                    `}
                    </script>
                  </Fragment>
                )
              default:
                return null
            }
          })}
        <script
          type='text/javascript'
          id='zsiqscript'
          src='https://salesiq.zoho.com/widget'
          defer
        />
        <script
          async
          is='script'
          type='text/javascript'
          id='zsiqchat'
          dangerouslySetInnerHTML={{
            __html: `
              var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "1fb4b4438512ae36653c1bc805af7586cacc421ebcd0308edb099e9dcd69c35c", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementById("zsiqscript");t.parentNode.insertBefore(s,t);
            `,
          }}
        />
      </NextHead>
    </>
  )
}

export default Head
