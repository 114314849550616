const validateImagesQuality = (quality: unknown): number => {
  let qualityLevel: number

  if (typeof quality === 'string') {
    qualityLevel = parseInt(quality)
  } else if (typeof quality === 'number') {
    qualityLevel = quality
  } else {
    throw new TypeError(
      'prerenderCount count must be a string containing a number or an integer.',
    )
  }

  if (Number.isNaN(qualityLevel)) {
    throw new TypeError(
      'prerenderCount count must be a string containing a number or an integer.',
    )
  }

  return qualityLevel
}

export default validateImagesQuality
