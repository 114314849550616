import { Address } from '@models/address'
import { ZipCode } from 'use-places-autocomplete'

export const SECTIONS_TYPES = {
  image: 'Spree::Cms::Sections::HeroImage',
  article: 'Spree::Cms::Sections::FeaturedArticle',
}

export const MODAL_VIEWS = {
  login: 'LOGIN_VIEW',
  signUp: 'SIGNUP_VIEW',
  request: 'REQUEST_VIEW',
  message: 'MESSAGE_VIEW',
  confirm: 'CONFIRM_VIEW',
  refresh: 'REFRESH_VIEW',
  shareWishlist: 'SHARE_WISHLIST',
  policyView: 'POLICY_VIEW',
  confirmRemoveWishlist: 'CONFIRM_REMOVE_WISHLIST',
  bulkView: 'BULK_VIEW',
  addAddress: 'ADD_ADDRESS',
  editAddress: 'EDIT_ADDRESS',
  cookiesModal: 'COOKIES_MODAL',
}

export const CREDIT_CARD_FORMATS = {
  number: '#### #### #### ####',
  expiringDate: '## / ##',
  cvc: '###',
}

export const INITIAL_ADDRESS = {
  country_name: '',
  firstname: '',
  lastname: '',
  company: '',
  address1: '',
  address2: '',
  zipcode: '' as ZipCode,
  city: '',
  phone: '',
  email: '',
  country_iso: '',
  saveNext: 'off',
  isDefault: false,
} as Address

export const MOBILE_WIDTH = 425
export const TABLET_WIDTH = 768
export const DESKTOP_WIDTH = 1024
export const DESKTOP_WIDTH_XL = 1280
export const PLACEHOLDER_IMG = '/product-img-placeholder.svg'

export const MENU_TYPE = {
  SIDE_MENU_ONLY: 'side menu only',
  UPPER_MENU_ONLY: 'only upper menu',
  UPPER_AND_SIDE_MENU: 'upper + side menu',
}

export const ICONS_SET_TYPE = {
  SET_1: 'set 1',
  SET_2: 'set 2',
  SET_3: 'set 3',
  SET_NINELIFE: 'set Ninelife',
  SET_NINEFIT: 'set Ninefit',
  SET_COPPERSTUDIO: 'set Copperstudio'
}

export const BANNER_TYPE = {
  TYPE_1: 'type 1',
  TYPE_2: 'type 2',
  NINTH_AVENUE: 'Ninth avenue',
  NINELIFE: 'Ninelife',
  NINEFIT: 'Ninefit',
}

export const MAIN_BANNER_TYPE = {
  TOP_LEFT: 'top-left',
  CENTER_LEFT: 'left-center',
  CENTERED: 'centered',
}

export const INITIAL_CREDIT_CARD = {
  attributes: {
    cc_type: '',
    default: false,
    last_digits: '',
    month: 1,
    name: '',
    year: 1,
  },
}

export const GOOGLE_ANALYTICS_TAG_TYPE = {
  GOOGLE_ADS: 'GAds',
  UA: 'UA/GA',
  GTM: 'GTM',
}

export const PAYMENT_METHODS = {
  worldPay: 'worldpay',
  eMerchantPay: 'emerchantpay',
  stripe: 'stripe',
  nuvei: 'nuvei',
  reach: 'reach'
}

export const ENGLISH_ALPHABETS_VALIDATION_MESSAGE =
  'Please only use characters from the English alphabet.'

export const SAVED_ADDRESSES_LOCAL_KEY = 'SAVED_ADRESSES'
export const RECENTLY_VIEWED_LOCAL_STORAGE_KEY = 'RECENTLY_VIEWED_LOCAL_STORAGE_KEY'
export const RECENTLY_VIEWED_STORAGE_LIMIT = 20