import { useHook, useMutationHook } from '@commerce/utils/use-hook'
import { mutationFetcher } from '@commerce/utils/default-fetcher'
import type { HookFetcherFn, MutationHook } from '@commerce/utils/types'
import type { UpdateItemHook } from '@commerce/types/cart'
import type { Provider } from '@commerce'

export type UseUpdateItem<
  H extends MutationHook<UpdateItemHook<any>> = MutationHook<UpdateItemHook>,
> = ReturnType<H['useHook']>

export const fetcher: HookFetcherFn<UpdateItemHook> = mutationFetcher

const fn = (provider: Provider) => provider.cart.useUpdateItem!

const useUpdateItem: UseUpdateItem = input => {
  const hook = useHook(fn)

  return useMutationHook({ fetcher, ...hook })(input)
}

export default useUpdateItem
