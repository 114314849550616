import { requireConfigValue } from '@framework/isomorphic-config'
import axios from 'axios'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'

const REQUEST_TIMEOUT = 10000

export const useHealth = () => {
  const lastRequest = useRef<number>()
  const apiUrl = requireConfigValue('apiHost')

  const router = useRouter()
  const [isInitialLoading, setIsInitialLoading] = useState(false)
  const checkHealth = async () => {
    try {
      const health = await axios.get(`${apiUrl}/health`)

      setIsInitialLoading(false)
      if (health.status !== 200 && !router.asPath.includes('maintenance')) {
        router.push('/maintenance')
      }
      if (health.status === 200 && router.asPath.includes('maintenance')) {
        router.push('/')
      }
    } catch (e) {
      setIsInitialLoading(false)
      if (!router.asPath.includes('maintenance') && e.code !== 'ERR_NETWORK' && e.message !== 'Request aborted') {
        router.push('/maintenance')
      }
    }
  }

  useEffect(() => {
    let interval: NodeJS.Timer
    const clickCallback = () => {
      if (
        !lastRequest.current ||
        Date.now() - lastRequest.current > REQUEST_TIMEOUT
      ) {
        checkHealth()
        lastRequest.current = Date.now()
      }
    }

    clickCallback()

    if (router.asPath.includes('maintenance')) {
      interval = setInterval(() => checkHealth(), 30000)
    } else if (globalThis) {
      globalThis.addEventListener('click', clickCallback)
    }

    return () => {
      if (globalThis) {
        globalThis.removeEventListener('click', clickCallback)
      }
      clearInterval(interval)
    }
  }, [router.asPath])

  return { isInitialLoading }
}
