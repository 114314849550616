import { LineItem } from '@commerce/types/cart'
import { GeocodeResult } from 'use-places-autocomplete'
import { NormalizedMenuTaxon, Option } from '@models'

export const isOutOfStock = (items: LineItem[] | undefined) => {
  const outOfStockItems = items?.filter(
    (lineItem: LineItem) => !lineItem.isInStock,
  )

  return outOfStockItems ? outOfStockItems.length > 0 : false
}

export const getValue = (results: GeocodeResult, config: string) => {
  const value = results.address_components.find(component =>
    component.types.includes(config),
  )

  return value?.long_name || ''
}

export const getValueState = (results: GeocodeResult, config: string) => {
  const value = results.address_components.find(component =>
    component.types.includes(config),
  )

  return { label: value?.long_name || ' ', value: value?.short_name || '' }
}
export const getCountry = (country: string | Option) => {
  if (!country) {
    return ''
  }

  return typeof country === 'string' ? country : country.value
}

export const getState = (state: string | Option) => {
  if (!state) {
    return ''
  }

  return typeof state === 'string' ? state : state.value
}

export const formatExpiryDate = (val: string) => {
  if (val === '') return ''
  let month = val.substring(0, 2)
  let year = val.substring(2, 4)

  if (month.length === 1 && +month[0] > 1) {
    month = `0${month[0]}`
  } else if (month.length === 2) {
    if (Number(month) === 0) {
      month = '01'
    } else if (Number(month) > 12) {
      year = `${month[1]}`
      month = `0${month[0]}`
    }
  }

  return `${month} / ${year}`
}

export const setCSSProperty = (varName: string, newProperty: string) => {
  document.documentElement.style.setProperty(varName, newProperty)
}

export const getTaxonId = (path: string, taxons?: NormalizedMenuTaxon[]) => {
  if (!taxons) return undefined
  const pathSplitted = path.split('?')[0].split('/')

  return taxons.find(taxon => {
    const taxonHrefSplitted = taxon.href.split('/')

    return taxonHrefSplitted.slice(-1)[0] === pathSplitted.slice(-1)[0]
  })?.id
}

export const debounce = (func: any, timeout = 300) => {
  let timer: any = null

  return (...args: any) => {
    clearTimeout(timer)
    timer = setTimeout(() => { func.apply(this, args) }, timeout)
  }
}

type EmptyObject<T> = { [K in keyof T]?: never };
type EmptyObjectOf<T> = EmptyObject<T> extends T ? EmptyObject<T> : never;
export function isEmptyObject<T extends object>(obj?: T | null | undefined): obj is EmptyObjectOf<T> | null | undefined {
  if (!obj) {
    return true
  }
  if (Object.keys(obj).length === 0) return true


  return false
}
