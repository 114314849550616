import '@assets/main.css'
import '@assets/chrome-bug.css'
import 'keen-slider/keen-slider.min.css'
import { FC, useEffect, useLayoutEffect, useState } from 'react'
import type { AppProps } from 'next/app'
import App from 'next/app'
import { useRouter } from 'next/router'
import { getStore } from 'api/getStore'
import { CommerceProvider } from '@framework'
import { Head } from '@components/common'
import { ManagedUIContext } from '@components/ui/context'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  BrandThemeProvider,
  setThemeVariables,
} from '@contexts/brandThemeContext'
import { StoreDataProvider } from '@contexts/storeDataContext'

import DefaultSEO from '@components/common/Head/DefaultSEO'
import { CookiesManager } from 'appComponents/CookiesManager/CookiesManager'

const Noop: FC = ({ children }) => <>{children}</>


const MyApp = ({ Component, pageProps }: AppProps) => {
  const Layout = (Component as any).Layout || Noop
  const CheckoutLayout = (Component as any).CheckoutLayout || Noop
  const [isThemeConfigured, setIsThemeConfigured] = useState(false)
  const { locale = 'en-US', asPath } = useRouter()

  useEffect(() => {
    document.body.classList?.remove('loading')
  }, [])

  useLayoutEffect(() => {
    setThemeVariables()
    setIsThemeConfigured(true)
  }, [])

  return (
    <>
      <ManagedUIContext>
        <StoreDataProvider>
          <DefaultSEO />
          <BrandThemeProvider>
            <Head />

            <CommerceProvider locale={locale}>
              {/* {isThemeConfigured && ( */}
              <div className='absolute w-full h-full bg-white' style={{ zIndex: isThemeConfigured ? 1 : 100, display: isThemeConfigured ? 'none' : 'block' }} />
              <CheckoutLayout pageProps={pageProps}>
                <Layout pageProps={pageProps}>
                  <Component {...pageProps} />
                  <ToastContainer
                    position='top-right'
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    draggable={false}
                    closeOnClick
                    pauseOnHover
                  />
                </Layout>
              </CheckoutLayout>
              {/* )} */}
              <CookiesManager pathname={asPath} />

            </CommerceProvider>
          </BrandThemeProvider>
        </StoreDataProvider>
      </ManagedUIContext>
    </>
  )
}

export default MyApp

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

export function reportWebVitals(metric: {
  id: string
  label: string
  name: string
  startTime: number
  value: number
}) {
  if (process.env.NEXT_PUBLIC_ENV !== 'prod') {
    console.log(metric)
  } else {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'perfomance-metric',
      id: metric.id,
      name: metric.name,
      startTime: metric.startTime,
      value: metric.value,
      label: metric.label,
    })
  }
}
