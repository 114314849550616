import type {
  Cart,
  LineItem,
  ProductVariant,
  SelectedOption,
} from '@commerce/types/cart'
import MissingLineItemVariantError from 'framework/spree/errors/MissingLineItemVariantError'
import { requireConfigValue } from 'framework/spree/isomorphic-config'
import type { ProductAttr } from '@spree/storefront-api-v2-sdk/types/interfaces/Product'
import type { Image } from '@commerce/types/common'
import { jsonApi } from '@spree/storefront-api-v2-sdk'
import createGetAbsoluteImageUrl from 'framework/spree/utils/create-get-absolute-image-url'
import getMediaGallery from 'framework/spree/utils/get-media-gallery'
import {
  AddressAttr,
  CreditCardAttr,
  IPromotion,
  LineItemAttr,
  OptionTypeAttr,
  OrderExtraAttr,
  Payments,
  Shipments,
  ShippingRateAttr,
  SpreeProductImage,
  SpreeSdkResponse,
  VariantAttr,
} from 'framework/spree/types'
import { INITIAL_ADDRESS, INITIAL_CREDIT_CARD } from '@constants/common'
import { TaxonAttr } from '@spree/storefront-api-v2-sdk/types/interfaces/Taxon'
import { isEmptyObject } from '@utils/helpers'

const placeholderImage = requireConfigValue('lineItemPlaceholderImageUrl') as
  | string
  | false

const isColorProductOption = (productOptionType: OptionTypeAttr) =>
  productOptionType.attributes.presentation === 'Color'

const normalizeVariant = (
  spreeSuccessResponse: SpreeSdkResponse,
  spreeVariant: VariantAttr,
): ProductVariant => {
  const spreeProduct = jsonApi.findSingleRelationshipDocument<ProductAttr>(
    spreeSuccessResponse,
    spreeVariant,
    'product',
  )

  if (spreeProduct === null) {
    throw new MissingLineItemVariantError(
      `Couldn't find product for variant with id ${spreeVariant.id}.`,
    )
  }

  const spreeVariantImageRecords =
    jsonApi.findRelationshipDocuments<SpreeProductImage>(
      spreeSuccessResponse,
      spreeVariant,
      'images',
    )

  let lineItemImage

  const variantImage = getMediaGallery(
    spreeVariantImageRecords,
    createGetAbsoluteImageUrl(requireConfigValue('imageHost') as string),
  )[0]

  const productProperties = jsonApi.findSingleRelationshipDocument(
    spreeSuccessResponse,
    spreeProduct,
    'product_properties',
  )

  if (variantImage) {
    lineItemImage = variantImage
  } else {
    const spreeProductImageRecords =
      jsonApi.findRelationshipDocuments<SpreeProductImage>(
        spreeSuccessResponse,
        spreeProduct,
        'images',
      )

    const productImage = getMediaGallery(
      spreeProductImageRecords,
      createGetAbsoluteImageUrl(requireConfigValue('imageHost') as string),
    )[0]

    lineItemImage = productImage
  }

  const image: Image =
    lineItemImage ??
    (placeholderImage === false ? undefined : { url: placeholderImage })

  return {
    id: spreeVariant.id,
    sku: spreeVariant.attributes.sku,
    name: spreeProduct.attributes.name,
    requiresShipping: true,
    price: parseFloat(spreeVariant.attributes.price),
    compareAtPrice: parseFloat(
      spreeVariant.attributes?.compare_at_price || '0',
    ),
    productProperties,
    listPrice: parseFloat(spreeVariant.attributes.price),
    image,
    isInStock: spreeVariant.attributes.in_stock,
    availableForSale: spreeVariant.attributes.purchasable,
    ...(spreeVariant.attributes.weight === '0.0'
      ? {}
      : {
          weight: {
            value: parseFloat(spreeVariant.attributes.weight),
            unit: 'KILOGRAMS',
          },
        }),
    // TODO: Add height, width and depth when Measurement type allows distance measurements.
  }
}

const normalizeLineItem = (
  spreeSuccessResponse: SpreeSdkResponse,
  spreeLineItem: LineItemAttr,
): LineItem => {
  const variant = jsonApi.findSingleRelationshipDocument<VariantAttr>(
    spreeSuccessResponse,
    spreeLineItem,
    'variant',
  )

  if (variant === null) {
    throw new MissingLineItemVariantError(
      `Couldn't find variant for line item with id ${spreeLineItem.id}.`,
    )
  }

  const product = jsonApi.findSingleRelationshipDocument<ProductAttr>(
    spreeSuccessResponse,
    variant,
    'product',
  )

  function fetchParentTaxons(
    taxon: TaxonAttr,
    spreeSuccessResponse: SpreeSdkResponse,
  ): TaxonAttr[] {
    if (!taxon) {
      return []
    }

    const parentData = taxon.relationships?.parent?.data

    if (parentData !== null) {
      const parentTaxon = jsonApi.findRelationshipDocuments<TaxonAttr>(
        spreeSuccessResponse,
        taxon,
        'parent',
      )[0]

      if (parentTaxon) {
        if (
          parentTaxon.attributes &&
          parentTaxon.attributes.hasOwnProperty('depth')
        ) {
          return [
            parentTaxon,
            ...fetchParentTaxons(parentTaxon, spreeSuccessResponse),
          ]
        } else {
          return []
        }
      }
    }

    return []
  }

  const taxon = jsonApi.findRelationshipDocuments<TaxonAttr>(
    spreeSuccessResponse,
    product,
    'taxons',
  )

  const taxonParents = taxon.map(el => {
    return fetchParentTaxons(el, spreeSuccessResponse)
  })

  const filteredTaxonParents = taxonParents.map(parents => {
    return parents.filter(parent => {
      return !(
        parent.attributes.name === 'Categories' && parent.attributes.depth === 0
      )
    })
  })

  if (product === null) {
    throw new MissingLineItemVariantError(
      `Couldn't find product for variant with id ${variant.id}.`,
    )
  }

  // CartItem.tsx expects path without a '/' prefix unlike pages/product/[slug].tsx and others.
  const path = `${product.attributes.slug}`

  const spreeOptionValues = jsonApi.findRelationshipDocuments(
    spreeSuccessResponse,
    variant,
    'option_values',
  )

  const options: SelectedOption[] = spreeOptionValues.map(spreeOptionValue => {
    const spreeOptionType =
      jsonApi.findSingleRelationshipDocument<OptionTypeAttr>(
        spreeSuccessResponse,
        spreeOptionValue,
        'option_type',
      )

    if (spreeOptionType === null) {
      throw new MissingLineItemVariantError(
        `Couldn't find option type of option value with id ${spreeOptionValue.id}.`,
      )
    }

    const label = isColorProductOption(spreeOptionType)
      ? spreeOptionValue.attributes.name
      : spreeOptionValue.attributes.presentation

    return {
      id: spreeOptionValue.id,
      name: spreeOptionType.attributes.presentation,
      value: label,
    }
  })

  return {
    id: spreeLineItem.id,
    variantId: variant.id,
    productId: product.id,
    productSku: product.attributes.sku,
    name: spreeLineItem.attributes.name,
    quantity: spreeLineItem.attributes.quantity,
    displayTotal: spreeLineItem.attributes.display_total,
    totalOnHand: spreeLineItem.attributes.total_on_hand,
    isInStock: product.attributes.in_stock,
    taxon: taxon
      .concat(...filteredTaxonParents)
      .sort((a, b) => a.attributes.depth - b.attributes.depth),
    discounts: [], // TODO: Implement when the template starts displaying them.
    path,
    variant: normalizeVariant(spreeSuccessResponse, variant),
    options,
  }
}

const normalizeCart = (
  spreeSuccessResponse: SpreeSdkResponse,
  spreeCart: OrderExtraAttr,
): Cart => {
  const lineItems = jsonApi
    .findRelationshipDocuments<LineItemAttr>(
      spreeSuccessResponse,
      spreeCart,
      'line_items',
    )
    .map(lineItem => normalizeLineItem(spreeSuccessResponse, lineItem))

  const shippingAddress =
    jsonApi.findSingleRelationshipDocument<AddressAttr>(
      spreeSuccessResponse,
      spreeCart,
      'shipping_address',
    ) || INITIAL_ADDRESS

  const billingAddress =
    jsonApi.findSingleRelationshipDocument<AddressAttr>(
      spreeSuccessResponse,
      spreeCart,
      'billing_address',
    ) || INITIAL_ADDRESS

  const payments =
    jsonApi.findSingleRelationshipDocument<Payments>(
      spreeSuccessResponse,
      spreeCart,
      'payments',
    ) || {}

  const creditCard = !isEmptyObject(payments)
    ? jsonApi.findSingleRelationshipDocument<CreditCardAttr>(
        spreeSuccessResponse,
        payments,
        'source',
      )
    : INITIAL_CREDIT_CARD

  const shipments =
    jsonApi.findSingleRelationshipDocument<Shipments>(
      spreeSuccessResponse,
      spreeCart,
      'shipments',
    ) || {}

  const rates = !isEmptyObject(shipments)
    ? jsonApi.findRelationshipDocuments<ShippingRateAttr>(
        spreeSuccessResponse,
        shipments,
        'shipping_rates',
      )
    : []
  const promotions = jsonApi.findRelationshipDocuments<IPromotion>(
    spreeSuccessResponse,
    spreeCart,
    'promotions',
  )
  return {
    id: spreeCart.id,
    taxTotal: spreeCart.attributes.tax_total,
    shipTotal: spreeCart.attributes.ship_total,
    displayTotal: spreeCart.attributes.display_total,
    displayShipTotal: spreeCart.attributes.display_ship_total,
    displayAdjustmentTotal: spreeCart.attributes.display_adjustment_total,
    createdAt: spreeCart.attributes.created_at.toString(),
    updatedAt: spreeCart.attributes.updated_at.toString(),
    wasUpdated: spreeCart.attributes.was_updated || false,
    currency: { code: spreeCart.attributes.currency },
    taxesIncluded: true,
    lineItems,
    promotions,
    lineItemsSubtotalPrice: parseFloat(spreeCart.attributes.item_total),
    subtotalPrice: parseFloat(spreeCart.attributes.item_total),
    totalPrice: parseFloat(spreeCart.attributes.total),
    customerId: spreeCart.attributes.token,
    number: spreeCart.attributes.number,
    email: spreeCart.attributes.email,
    adjustmentTotal: spreeCart.attributes.adjustment_total,
    discounts: [], // TODO: Implement when the template starts displaying them.
    shippingAddress,
    billingAddress,
    creditCard: creditCard.attributes,
    shippingMethods: rates,
    gtm_events: spreeCart.attributes.gtm_events,
    checkoutState: spreeCart.attributes.state,
  }
}

export { normalizeLineItem }

export default normalizeCart
