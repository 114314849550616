import Cookies from 'js-cookie'
import { requireConfigValue } from '@framework/isomorphic-config'

export const getCartToken = () =>
  Cookies.get(requireConfigValue('cartCookieName') as string) || false

export const setCartToken = (cartToken: string) => {
  const cookieOptions = {
    expires: requireConfigValue('cartCookieExpire') as number,
  }

  Cookies.set(
    requireConfigValue('cartCookieName') as string,
    cartToken,
    cookieOptions,
  )
}

export const removeCartToken = () => {
  Cookies.remove(requireConfigValue('cartCookieName') as string)
}
