import type {
  Product,
  ProductImage,
  ProductPrice,
  ProductVariant,
} from '@commerce/types/product'
import type { RelationType } from '@spree/storefront-api-v2-sdk/types/interfaces/Relationships'
import { jsonApi } from '@spree/storefront-api-v2-sdk'
import { JsonApiDocument } from '@spree/storefront-api-v2-sdk/types/interfaces/JsonApi'
import expandOptions from 'framework/spree/utils/expand-options'

import { requireConfigValue } from 'framework/spree/isomorphic-config'
import createGetAbsoluteImageUrl from 'framework/spree/utils/create-get-absolute-image-url'
import getMediaGallery from 'framework/spree/utils/get-media-gallery'
import getProductPath from 'framework/spree/utils/get-product-path'
import MissingPrimaryVariantError from 'framework/spree/errors/MissingPrimaryVariantError'
import MissingOptionValueError from 'framework/spree//errors/MissingOptionValueError'
import type {
  ExpandedProductOption,
  ProductExtraAttr,
  SpreeSdkResponse,
  VariantAttr,
} from 'framework/spree/types'
import { TaxonAttr } from '@spree/storefront-api-v2-sdk/types/interfaces/Taxon'

const placeholderImage = requireConfigValue('productPlaceholderImageUrl') as
  | string
  | false

const imagesOptionFilter = requireConfigValue('imagesOptionFilter') as
  | string
  | false

export const normalizeIncluded: any = (
  spreeSuccessResponse: SpreeSdkResponse,
) =>
  spreeSuccessResponse?.included?.reduce(
    (acc, curr) =>
      curr.type === 'product_property'
        ? { ...acc, [curr.id]: curr.attributes }
        : acc,
    {},
  )

const normalizeProduct = (
  spreeSuccessResponse: SpreeSdkResponse,
  spreeProduct: ProductExtraAttr,
  normalizedIncluded?: any,
): Product => {
  const normalizedIncludedUse =
    normalizedIncluded || normalizeIncluded(spreeSuccessResponse)

  const productProperties =
    normalizedIncludedUse &&
    Array.isArray(spreeProduct?.relationships?.product_properties?.data)
      ? spreeProduct?.relationships?.product_properties?.data?.reduce(
          (acc: Record<string, unknown>, item: any) => ({
            ...acc,
            [normalizedIncludedUse[item.id].name]:
              normalizedIncludedUse[item.id],
          }),
          {},
        )
      : {}

  const spreePrimaryVariant =
    jsonApi.findSingleRelationshipDocument<VariantAttr>(
      spreeSuccessResponse,
      spreeProduct,
      'primary_variant',
    )

  if (spreePrimaryVariant === null) {
    throw new MissingPrimaryVariantError(
      `Couldn't find primary variant for product with id ${spreeProduct.id}.`,
    )
  }

  function fetchParentTaxons(
    taxon: TaxonAttr,
    spreeSuccessResponse: SpreeSdkResponse,
  ): TaxonAttr[] {
    if (!taxon) {
      return []
    }

    const parentData = taxon.relationships?.parent?.data

    if (parentData !== null) {
      const parentTaxon = jsonApi.findRelationshipDocuments<TaxonAttr>(
        spreeSuccessResponse,
        taxon,
        'parent',
      )[0]

      if (parentTaxon) {
        if (
          parentTaxon.attributes &&
          parentTaxon.attributes.hasOwnProperty('depth')
        ) {
          return [
            parentTaxon,
            ...fetchParentTaxons(parentTaxon, spreeSuccessResponse),
          ]
        } else {
          return []
        }
      }
    }

    return []
  }

  const taxon = jsonApi.findRelationshipDocuments<TaxonAttr>(
    spreeSuccessResponse,
    spreeProduct,
    'taxons',
  )

  const taxonParents = taxon.map(el => {
    return fetchParentTaxons(el, spreeSuccessResponse)
  })

  const filteredTaxonParents = taxonParents.map(parents => {
    return parents.filter(parent => {
      return !(
        parent.attributes.name === 'Categories' && parent.attributes.depth === 0
      )
    })
  })
  const { sku } = spreePrimaryVariant.attributes
  const price: ProductPrice = {
    value: parseFloat(spreeProduct.attributes.price),
    currencyCode: spreeProduct.attributes.currency,
    compareAtPrice: parseFloat(spreeProduct.attributes?.compare_at_price || ''),
    // save:
    //   spreeProduct.attributes?.compare_at_price !== '0.0'
    //     ? (
    //         parseFloat(spreeProduct.attributes?.compare_at_price) -
    //         parseFloat(spreeProduct.attributes.price)
    //       ).toFixed(2)
    //     : null,
    // savePercent:
    //   spreeProduct.attributes?.compare_at_price !== '0.0'
    //     ? (parseFloat(spreeProduct.attributes?.compare_at_price) -
    //         parseFloat(spreeProduct.attributes.price)) /
    //       (parseFloat(spreeProduct.attributes.compare_at_price) / 100)
    //     : null,
  }

  const hasNonMasterVariants =
    (spreeProduct.relationships.variants.data as RelationType[]).length > 1

  const showOptions =
    (requireConfigValue('showSingleVariantOptions') as boolean) ||
    hasNonMasterVariants

  let options: ExpandedProductOption[] = []

  const spreeVariantRecords = jsonApi.findRelationshipDocuments(
    spreeSuccessResponse,
    spreeProduct,
    'variants',
  )

  // Use variants with option values if available. Fall back to
  // Spree primary_variant if no explicit variants are present.
  const spreeOptionsVariantsOrPrimary =
    spreeVariantRecords.length === 0
      ? [spreePrimaryVariant]
      : spreeVariantRecords

  const variants: ProductVariant[] = spreeOptionsVariantsOrPrimary.map(
    spreeVariantRecord => {
      let variantOptions: ExpandedProductOption[] = []

      if (showOptions) {
        const spreeOptionValues = jsonApi.findRelationshipDocuments(
          spreeSuccessResponse,
          spreeVariantRecord,
          'option_values',
        )

        // Only include options which are used by variants.

        spreeOptionValues.forEach(spreeOptionValue => {
          variantOptions = expandOptions(
            spreeSuccessResponse,
            spreeOptionValue,
            variantOptions,
          )

          options = expandOptions(
            spreeSuccessResponse,
            spreeOptionValue,
            options,
          )
        })
      }

      return {
        id: spreeVariantRecord.id,
        options: variantOptions,
      }
    },
  )

  const spreePrimaryVariantImageRecords = jsonApi.findRelationshipDocuments(
    spreeSuccessResponse,
    spreePrimaryVariant,
    'images',
  )

  let spreeVariantImageRecords: JsonApiDocument[]

  if (imagesOptionFilter === false) {
    spreeVariantImageRecords = spreeVariantRecords.reduce<JsonApiDocument[]>(
      (accumulatedImageRecords, spreeVariantRecord) => [
        ...accumulatedImageRecords,
        ...jsonApi.findRelationshipDocuments(
          spreeSuccessResponse,
          spreeVariantRecord,
          'images',
        ),
      ],
      [],
    )
  } else {
    const spreeOptionTypes = jsonApi.findRelationshipDocuments(
      spreeSuccessResponse,
      spreeProduct,
      'option_types',
    )

    const imagesFilterOptionType = spreeOptionTypes.find(
      spreeOptionType => spreeOptionType.attributes.name === imagesOptionFilter,
    )

    if (!imagesFilterOptionType) {
      console.warn(
        `Couldn't find option type having name ${imagesOptionFilter} for product with id ${spreeProduct.id}.` +
          ' Showing no images for this product.',
      )

      spreeVariantImageRecords = []
    } else {
      const imagesOptionTypeFilterId = imagesFilterOptionType.id
      const includedOptionValuesImagesIds: string[] = []

      spreeVariantImageRecords = spreeVariantRecords.reduce<JsonApiDocument[]>(
        (accumulatedImageRecords, spreeVariantRecord) => {
          const spreeVariantOptionValuesIdentifiers: RelationType[] =
            spreeVariantRecord.relationships.option_values.data

          const spreeOptionValueOfFilterTypeIdentifier =
            spreeVariantOptionValuesIdentifiers.find(
              (spreeVariantOptionValuesIdentifier: RelationType) =>
                imagesFilterOptionType.relationships.option_values.data.some(
                  (filterOptionTypeValueIdentifier: RelationType) =>
                    filterOptionTypeValueIdentifier.id ===
                    spreeVariantOptionValuesIdentifier.id,
                ),
            )

          if (!spreeOptionValueOfFilterTypeIdentifier) {
            throw new MissingOptionValueError(
              `Couldn't find option value related to option type with id ${imagesOptionTypeFilterId}.`,
            )
          }

          const optionValueImagesAlreadyIncluded =
            includedOptionValuesImagesIds.includes(
              spreeOptionValueOfFilterTypeIdentifier.id,
            )

          if (optionValueImagesAlreadyIncluded) {
            return accumulatedImageRecords
          }

          includedOptionValuesImagesIds.push(
            spreeOptionValueOfFilterTypeIdentifier.id,
          )

          return [
            ...accumulatedImageRecords,
            ...jsonApi.findRelationshipDocuments(
              spreeSuccessResponse,
              spreeVariantRecord,
              'images',
            ),
          ]
        },
        [],
      )
    }
  }

  const spreeImageRecords = [
    ...spreePrimaryVariantImageRecords,
    ...spreeVariantImageRecords,
  ]

  const productImages = getMediaGallery(
    spreeImageRecords,
    createGetAbsoluteImageUrl(requireConfigValue('imageHost') as string),
  )

  const images: ProductImage[] =
    productImages.length === 0
      ? placeholderImage === false
        ? []
        : [{ url: placeholderImage }]
      : productImages

  const { slug } = spreeProduct.attributes
  const path = getProductPath(spreeProduct)

  return {
    id: spreeProduct.id,
    name: spreeProduct.attributes.name,
    description: spreeProduct.attributes.description,
    images,
    isInStock: spreeProduct.attributes.in_stock,
    productProperties,
    rating: spreeProduct.attributes.rating,
    minDeliveryDays: spreeProduct.attributes.min_delivery_days,
    maxDeliveryDays: spreeProduct.attributes.max_delivery_days,
    totalOnHand: spreeProduct.attributes.total_on_hand,
    displayPrice: spreeProduct.attributes.display_price,
    discontinueOn: spreeProduct.attributes.discontinue_on,
    metaDesctiption: spreeProduct.attributes.meta_description,
    variants,
    features: spreeProduct.attributes.features || null,
    options,
    price,
    slug,
    path,
    sku,
    taxon: taxon
      .concat(...filteredTaxonParents)
      .sort((a, b) => a.attributes.depth - b.attributes.depth),
  }
}

export default normalizeProduct
