import type { SWRHook } from '@commerce/utils/types'
import useSearch, { UseSearch } from '@commerce/product/use-search'
import type { Product, SearchProductsHook } from '@commerce/types/product'
import type { GraphQLFetcherResult } from '@commerce/api'
import { IProducts } from '@spree/storefront-api-v2-sdk/types/interfaces/Product'
import normalizeProduct, {
  normalizeIncluded,
} from '@framework/utils/normalizations/normalize-product'
import { requireConfigValue } from '@framework/isomorphic-config'

const imagesSize = requireConfigValue('imagesSize') as string
const imagesQuality = requireConfigValue('imagesQuality') as number

const nextToSpreeSortMap: { [key: string]: string } = {
  'trending-desc': 'available_on',
  'latest-desc': 'updated_at',
  'price-asc': 'price',
  'price-desc': '-price',
}

export const handler: SWRHook<SearchProductsHook> = {
  // Provide fetchOptions for SWR cache key
  fetchOptions: {
    url: 'products',
    query: 'list',
  },
  async fetcher({ input, options, fetch }) {
    // This method is only needed if the options need to be modified before calling the generic fetcher (created in createFetcher).

    console.info(
      'useSearch fetcher called. Configuration: ',
      'input: ',
      input,
      'options: ',
      options,
    )

    const taxons = [
      input.taxon ? input.taxon : input.categoryId,
      input.brandId,
    ].filter(Boolean)

    const filter: any = {
      filter: {
        ...(taxons.length > 0 ? { taxons: taxons.join(',') } : {}),
        ...(input.search ? { name: input.search } : {}),
      },
    }

    if (input.minPrice || input.maxPrice) {
      filter.filter.price = `${input.minPrice !== '0' ? input.minPrice : '0'}${
        input.maxPrice !== '0' && input.maxPrice !== ''
          ? `,${input.maxPrice}`
          : ',9999999'
      }`
    }

    if (input.brands.length > 0) {
      filter.filter['[properties][brand]'] = input.brands.toString()
    }

    const defaultSorting = '-available_on'
    const sort = input.sort
      ? { sort: nextToSpreeSortMap[input.sort] }
      : { sort: defaultSorting }

    const { data: spreeSuccessResponse } = await fetch<
      GraphQLFetcherResult<
        IProducts & {
          meta?: {
            filters: any
          }
        }
      >
    >({
      variables: {
        methodPath: 'products.list',
        arguments: [
          {},
          {
            include:
              'product_properties,primary_variant,variants,images,option_types,variants.option_values',
            per_page: input.per_page || 48,
            ...filter,
            ...sort,
            image_transformation: {
              quality: imagesQuality,
              size: imagesSize,
            },
            page: input.page || 1,
          },
        ],
      },
    })

    const normalizedIncluded: any = normalizeIncluded(spreeSuccessResponse)

    const normalizedProducts: Product[] = spreeSuccessResponse.data.map(
      (spreeProduct: any) =>
        normalizeProduct(
          spreeSuccessResponse,
          spreeProduct,
          normalizedIncluded,
        ),
    )

    const found = spreeSuccessResponse.data.length > 0

    if (!found) {
      return { products: [], found }
    }

    return {
      products: found ? normalizedProducts : [],
      found,
      metaBrands:
        spreeSuccessResponse?.meta?.filters?.product_properties?.filter(
          (item: any) => item.name === 'brand',
        )[0].values,
      totalPages: spreeSuccessResponse?.meta?.total_pages,
      totalCounts: spreeSuccessResponse?.meta?.total_count,
    }
  },

  useHook: ({ useData }) => {
    const useWrappedHook: ReturnType<SWRHook<SearchProductsHook>['useHook']> = (
      input = {},
    ) =>
      useData({
        input: [
          ['search', input.search],
          ['categoryId', input.categoryId],
          ['brandId', input.brandId],
          ['sort', input.sort],
          ['minPrice', input.minPrice],
          ['maxPrice', input.maxPrice],
          ['brands', input.brands],
          ['page', input.page],
          ['per_page', input.per_page],
          ['taxon', input.taxon],
        ],
        swrOptions: {
          revalidateOnFocus: false,
          // revalidateOnFocus: false means do not fetch products again when website is refocused in the web browser.
          ...input.swrOptions,
        },
      })

    return useWrappedHook
  },
}

export default useSearch as UseSearch<typeof handler>
