import MissingConfigurationValueError from '@framework/errors/MissingConfigurationValueError'
import type { NonUndefined, ValueOf } from '@framework/types'
import { PHASE_PRODUCTION_BUILD } from 'next/constants'

const requireConfig = <T>(isomorphicConfig: T, key: keyof T) => {
  const valueUnderKey = isomorphicConfig[key]

  if (typeof valueUnderKey === 'undefined' && process.env.NEXT_PHASE !== PHASE_PRODUCTION_BUILD) {
    throw new MissingConfigurationValueError(
      `Value for configuration key ${key} was undefined.`,
    )
  }

  return valueUnderKey as NonUndefined<ValueOf<T>>
}

export default requireConfig
