import {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react'
import { StoreData } from '@models'
import { getStore, StoreTag } from 'api/getStore'
import { isEmptyObject } from '@utils/helpers'

type Store = StoreData & { isLoading: boolean }

export const StoreDataContext = createContext({} as Store)

StoreDataContext.displayName = 'StoreDataContext'

export const StoreDataProvider: FC = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [value, setValue] = useState({
    data: {
      attributes: {
        contact_phone: '',
        currency_format: '',
        currency_symbol: '',
        customer_support_email: '',
        redirect_on_add_to_cart: true,
        default_currency: '',
        favicon_path: null,
        watermark_img: '',
        name: '',
        seo_title: '',
        settings: {},
        orders_format: {},
        brand_theme: {},
        breadcrumbs_is_active: false,
        google_analytics_token: '',
        google_ads_token: '',
        show_payment_icons: {
          amex_icon: true,
          apple_pay_icon: true,
          discover_icon: true,
          google_pay_icon: true,
          master_card_icon: true,
          visa_icon: true,
        },
        track_inventory_levels: true,
        is_home_brand: false,
      },
      countryName: '',
      countryISO: '',
      zipRequired: true,
      statesRequired: false,
      storeTags: [] as StoreTag[],
    },
  } as unknown as StoreData)
  const context = useMemo(() => ({ ...value, isLoading }), [value, isLoading])

  useEffect(() => {
    const store = JSON.parse(localStorage.getItem('store') ?? '{}')

    if (!isEmptyObject(store)) setValue(store)
    setIsLoading(true)
    getStore()
      .then((res) => {
        if (!isEmptyObject(res))
          localStorage.setItem('store', JSON.stringify(res))
        
        setValue(res)
      })
      .finally(() => setIsLoading(false))
  }, [])

  return <StoreDataContext.Provider value={context} {...props} />
}

export const useStoreDataContext = () => {
  const context = useContext<Store>(StoreDataContext)

  if (context === undefined) {
    throw new Error(
      'useStoreDataContext must be used within a StoreDataProvider',
    )
  }

  return context
}
