import axios from 'axios'
import { requireConfigValue } from '@framework/isomorphic-config'
import { StoreData } from 'models/storeData'
import { jsonApi } from '@spree/storefront-api-v2-sdk'

export interface StoreTag {
  id: string
  type: string
  attributes: {
    code: string
    type: string
  }
}

export const getStore = async (): Promise<StoreData> => {
  const apiUrl = requireConfigValue('apiHost')

  const { data: res } = await axios.get(`${apiUrl}/api/custom/stores`, {
    params: {
      include: 'default_country,store_tags',
    },

    headers: {
      'Cache-Control': 'max-age=600, must-revalidate',
    },
  })

  const storeTags: StoreTag[] = jsonApi.findRelationshipDocuments(
    res,
    res.data,
    'store_tags',
  )

  return {
    data: {
      ...res.data,
      countryName: res.included[0].attributes.name,
      countryISO: res.included[0].attributes.iso,
      zipRequired: res.included[0].attributes.zipcode_required,
      statesRequired: res.included[0].attributes.states_required,
      storeTags,
    },
  }
}
